<template>
  <div class="solution">
    <Banner></Banner>
    <Total></Total>
    <Core></Core>
    <Application></Application>
    <Case></Case>
  </div>
</template>

<script>
import Banner from "@/components/solution/Banner.vue";
import Case from "@/components/solution/Case.vue";
import Core from "@/components/solution/Core.vue";
import Application from "@/components/solution/Application.vue";
import Total from "@/components/solution/Total.vue";

export default {
  name: "Solution",
  data() {
    return {};
  },
  components: {
    Banner,
    Case,
    Core,
    Application,
    Total,
  },
  props: {},

  create() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.solution {
  width: 100%;
}
</style>
