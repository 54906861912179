<template>
  <div class="views-solution-Case  ">
    <div class="solution-title case-title ">
      <div class="line-content head-top ">
        <span class="line-item-left"></span>
        <h2 class="line-call">客户案例</h2>
        <span class="line-item"></span>
      </div>
      <p class="line-title ">
        CUSTOMER CASE
      </p>
      <div class="solution-img">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          class="img-item "
          data-wow-duration="1.5s"
          data-wow-delay="0.1s"
        >
          <img v-lazy="item.srcImg" alt="" />
        </div>
      </div>
    </div>
    <page-more :newsLis="newsLis"></page-more>
  </div>
</template>

<script>
import WOW from "wowjs";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "Case",
  data() {
    return {
      newsLis: [
        {
          name: "solution",
          textImg: require("@/assets/images/solution/icon-1.png"),
        },
      ],
      //   backImg: [require("@/assets/images/product/more.png")],
      bottomkImg: require("@/assets/images/solution/input.png"),

      imgList: [
        { srcImg: require("@/assets/images/solution/icon-3.png") },
        { srcImg: require("@/assets/images/solution/icon-4.png") },
        { srcImg: require("@/assets/images/solution/icon-5.png") },
      ],
    };
  },
  components: {
    pageMore,
  },
  props: {},

  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-solution-Case {
  width: 100%;
  margin-top: 1.25rem;
  .solution-title {
    background: url(../../assets/images/solution/back.png) no-repeat;
    background-size: cover;

    // height: 375px;
    .solution-img {
      display: flex;
      flex: 1;
      justify-content: center;
      align-self: center;
      padding: 0.7125rem 0 0.35rem;
      margin: 0 auto;
    }

    .img-item {
      // width: 266px;
      // height: 106px;

      border-radius: 10px;
      // margin-left: 0.5625rem;
      margin: 0 22px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .case-title {
    .line-content {
      padding-top: 31px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        font-family: SimHei;
        color: #ffff;
        white-space: nowrap;
        letter-spacing: 7px;
        padding-bottom: 0.225rem;
      }
      .line-item {
        background: url("../../assets/images/product/line-right-while.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-left-while.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.25rem;
      font-family: SimHei;
      color: #fff;
      letter-spacing: 3px;
    }
  }
}

@media screen and (max-width: 680px) {
  .line-item {
    width: 0.75rem !important;
  }
  .line-item-left {
    width: 0.75rem !important;
  }
}
</style>
