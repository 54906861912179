<template>
  <div class="views-solution-Core">
    <div class="CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">核心优势</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">CORE ADVANTAGES</h3>
    </div>
    <div class="core-header ">
      <div class="core-foot " style="position: relative;">
        <!--蓝色模块-->
        <div ref="core-item-move" class="core-item-move"
             style="position: absolute; transition: all .5s;"
             @mouseout="onMouseout"
             @mouseover="onMouseover(zhaopinActive,true)"
        >
          <div class="core-img">
            <img
                :src="currentItem.activeImg"
                alt=""
            />
          </div>
          <span>{{ currentItem.name }}</span>
          <p>{{ currentItem.content }}</p>
        </div>
        <!--列表-->
<!-- 列表的上方 盖了一层蓝色 写了两层达到效果  -->
        <div
            class="core-item"
            v-for="(item, index) in tabList"
            :key="index"
            @mouseout="onMouseout"
            @mouseover="onMouseover(index,true)"
            :ref="`coreItem${index}`"
        >
          <!--            :class="{ active: index === 0 }"-->
          <div class="core-img">
            <img
                v-lazy="item.img"
                alt=""
            />
          </div>
          <span>{{ item.name }}</span>
          <!--          <p>{{ item.content }}</p>-->
        </div>
      </div>
      <div class="back-Img"></div>
    </div>
    <!--移动端-->
    <div class="core-mobile-header ">
      <div class="core-mobile-foot">
        <div
            class="core-mobile-item"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ active: index === zhaopinMobileActive }"
            @click="onZhaopinClick(index)"
        >
          <div class="mobile-left">
            <img
                :src="zhaopinMobileActive === index ? item.activeImg : item.img"
                alt=""
            />
          </div>
          <div class="mobile-right">
            <span>{{ item.name }}</span>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import WOW from "wowjs";

  let Time = null; //定义一个全局定时器
  export default {
    name: "Core",

    props: {},
    data() {
      return {
        hover: false,
        zhaopinActive: 0,
        zhaopinMobileActive: 0,
        num: 0,
        translateLeft: 0,
        tabList: [
          {
            name: "智能运营",
            img: require("@/assets/images/solution/core-1.png"),
            activeImg: require("@/assets/images/solution/core-1.1.png"),
            content:
              "18省市成功运营经验，BI报表和数字大屏实现业务多维统计和可视化动态展示，助力企业精细化运营",
          },
          {
            name: "技术成熟",
            img: require("@/assets/images/solution/core-3.png"),
            activeImg: require("@/assets/images/solution/core-3.1.png"),
            content:
              "自动对任务打标分批和智能分配，在同等成单率的前提下减少坐席外呼工作量。专利背书级人机协同服务能力，智能营销让服务效率再提升2.5倍",
          },
          {
            name: "系统解耦",
            img: require("@/assets/images/solution/core-4.png"),
            activeImg: require("@/assets/images/solution/core-4.1.png"),
            content:
              "10年呼叫中心服务经验，成熟的平台架构能力，已完成系统解耦助力技术产品化和功能多样化，且支持角色权限功能保障系统数据安全",
          },
          {
            name: "触点闭环",
            img: require("@/assets/images/solution/core-5.png"),
            activeImg: require("@/assets/images/solution/core-5.1.png"),
            content:
              "打造集语音营销、短信拓客、权益增值等一站式综合服务平台，整合渠道资源，在不增加任何业务流程的原有服务链上增加客户触点和营销拓客机会",
          },
          {
            name: "大数据服务",
            img: require("@/assets/images/solution/core-2.png"),
            activeImg: require("@/assets/images/solution/core-2.1.png"),
            content:
              "融合多源数据，建设高质量的大数据生产中心，赋能企业管理决策",
          },
        ],
        currentItem: {}
      };
    },

    mounted() {
      this.currentItem = this.tabList[0]
      //定时器开关
      this.setime();
      this.$nextTick(() => {
        let wow = new WOW.WOW({
          boxClass: "wow",
          animateClass: "animated",
          offset: 0,
          mobile: true,
          live: false,
        });
        wow.init();
      });
    },
    create() {
    },
    beforeDestroy() {
      clearInterval(Time);
    },
    methods: {
      //设置定时器
      setime() {
        Time = setInterval(() => {
          //定时器自动增加
          this.zhaopinActive++;
          //当定时器的长度用完所有的轮播的内容
          if (this.zhaopinActive >= this.tabList.length) {
            //回到初始值
            this.zhaopinActive = 0;
          }
          this.onMouseover(this.zhaopinActive)
          //定时器的秒数
        }, 2000);
      },
      //通过触发事件
      onZhaopinClick(index) {
        this.zhaopinMobileActive = index
      },
      onMouseout() {
        this.setime();
      },
      /**
       * isFlag 为false 定时器移动 为true 手移动
       * @param index
       * @param isFlag
       */
      //移入
      onMouseover(index, isFlag = false) {
        this.zhaopinActive = index
        this.currentItem = this.tabList[index]
        //this.$refs[`coreItem${index}`][0]
        //$refs绑定在元素上是dom
        //$refs绑定在组件上是实例对象
        // console.log(this.$refs)
        // offsetLeft 获取元素的 offsetLeft
        let move = this.$refs[`coreItem${index}`][0] //获取鼠标移入的底层dom
        //this.$refs['core-item-move'].style.transform  让蓝色模块移动到鼠标经过的dom位置
        this.$refs['core-item-move'].style.transform = `translateX(${move.offsetLeft}px)`
        if (isFlag) {//如果是手移动的话就停止自动移动 防止定时器和手动的一起移动
          clearInterval(Time);
        }
      }
    },
  };
</script>
<style lang="less" scoped>
  .views-solution-Core {
    // height: 7.5rem;

    padding-top: 2.625rem;
    padding-bottom: 1.125rem;

    .CallOut-title {
      .line-content {
        transform: translateX();
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 63%;
        margin: 0 auto;

        .line-call {
          font-size: 0.5rem;
          font-family: SimHei;
          color: #333333;
          letter-spacing: 7px;

          white-space: nowrap;
        }

        .line-item {
          background: url("../../assets/images/product/line-title-right.png") no-repeat 100% 100%;
          height: 2px;
          width: 4.6375rem;
          background-size: contain;
        }

        .line-item-left {
          background: url("../../assets/images/product/line-title-left.png") no-repeat 100% 100%;
          height: 2px;
          width: 4.6375rem;
          background-size: contain;
        }
      }

      .line-title {
        margin-top: 19px;
        font-size: 0.3rem;
        font-family: SimHei;
        color: #666666;
        letter-spacing: 3px;
      }
    }

    .core-header {
      width: 74%;
      margin: 55px auto 0;
      height: 366px;

      .core-foot {
        display: flex;
        justify-content: center;
        height: 100%;

        .core-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          width: 30%;

          img {
            width: 49px;
            height: 49px;
          }

          span {
            margin-top: 33px;
            font-size: 0.325rem;
            font-family: SimHei;
            color: #333333;
            letter-spacing: 2px;
          }

        }

        .core-item-move {
          cursor: pointer;
          width: 281px;
          height: 366px;
          left: -50px;
          //left:0 dom向左向右偏移的初始值

          .core-img {
            margin: 59px 113px 26px;

            img {
              width: 55px;
              height: 55px;
            }
          }

          background: linear-gradient(0deg, #3d8ae5, #52b9f9);
          box-shadow: 0px 7px 20px 0px rgba(18, 29, 87, 0.2);
          border-radius: 10px;

          span {
            color: #ffffff;
            margin-top: 0;
            font-size: 24px;
          }

          p {
            font-size: 0.175rem;
            font-family: SimHei;
            padding: 30px 39px 0 39px;
            color: #ffffff;
            letter-spacing: 1.5px;
            line-height: 20px;
       
          }
        }
      }
    }

    .core-mobile-header {
      display: none;
      margin: 55px auto 0;

      .core-mobile-foot {
        .core-mobile-item {
          width: 80%;
          margin: 0 auto 0.375rem;
          padding: 0.4375rem 0.9375rem 0.625rem;
          box-shadow: 0 23px 39px #3d8ae5;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          border-radius: 8px;

          .mobile-right {
            padding: 0.625rem 0;

            span {
              font-size: 0.25rem;
            }

            p {
              font-size: 0.2rem;
            }
          }

          img {
            width: 38px;
            height: 32px;
          }

          &.active {
            background: linear-gradient(0deg, #52b9f9, #3d8ae5);
            box-shadow: 0 23px 39px #3d8ae5;

        

            span {
              color: #ffffff;
              font-size: 0.35rem;
            }

            p {
              padding-top: 0.375rem;
              display: block;
              font-size: 0.175rem;
              font-family: SimHei;
              color: #ffffff;
            }
          }
        }
      }
    }

    position: relative;
    overflow: hidden;

    .back-Img {
      position: absolute;
      z-index: -1;
      background: url(../../assets/images/product/VI-1.png) no-repeat;
      background-size: contain;
      height: 100%;
      width: 100%;
      left: 48%;
      bottom: 15%;
    }
  }

  @media screen and (max-width: 1200px) {
    .views-solution-Core {
      .core-header {
        width: 83%;
      }

      .Total-content {
        transform: scale(0.8);
      }

      .back-Img {
        transform: scale(0.8);
      }
    }
  }

  @media screen and (max-width: 992px) {
    .views-solution-Core {
      .core-header {
        .core-foot {
          .core-item-move {
            cursor: pointer;
            width: 180px;
            height: 250px;
            left: -30px;
            top: 80px;

            .core-img {
              margin: 10px;

              img {
                width: 55px;
                height: 55px;
              }
            }

            p {
              padding: 10px 29px 0 29px;
              line-height: 15px;
            }
          }
        }
      }

      .Total-content {
        transform: scale(0.7);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .views-solution-Core {
      .core-header {
        display: none;
      }

      .core-mobile-header {
        display: block;
      }

      .Total-content {
        transform: scale(0.6);
      }
    }
  }

  @media screen and (max-width: 680px) {
 
    .line-item {
      width: 0.75rem;
    }

    .line-item-left {
      width: 0.75rem;
    }
  }
</style>
