<template>
  <div class="views-solution-Application ">
    <div class="CallOut-title">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">场景应用</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">APPLICATION SCENARIO</h3>
    </div>
    <div class="cricle-img  ">
      <!-- <cricle> </cricle> -->
      <img v-lazy="img" alt="" />
    </div>
    <div class="back-Img"></div>
  </div>
</template>
<script>
import WOW from "wowjs";
import cricle from "@/components/solution/cricle.vue";
export default {
  name: "Application",
  components: {
    cricle,
  },

  props: {},
  data() {
    return {
      img: require("@/assets/images/solution/back-2.png"),
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-solution-Application {
  padding-top: 1rem;
  .CallOut-title {
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        font-family: SimHei;
        color: #333333;
        letter-spacing: 7px;
        white-space: nowrap;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.25rem;
      font-family: SimHei;
      color: #666666;
      letter-spacing: 3px;
    }
  }
  .cricle-img {
    img {
      max-height: 100%;
      max-width: 100%;
    }
    margin: 60px auto 0;
  }
  position: relative;
  overflow: hidden;
  .back-Img {
    position: absolute;
    z-index: -1;
    background: url(../../assets/images/product/VI-1.png) no-repeat;
    background-size: contain;

    height: 60%;
    width: 55%;

    top: 0;
    left: -17%;
  }
}

@media screen and (max-width: 1200px) {
  .views-solution-Application {
    .back-Img {
      transform: scale(0.8);
    }
  }
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 680px) {
  .views-solution-Application {
    .line-item {
      width: 0.75rem;
    }
    .line-item-left {
      width: 0.75rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .views-solution-Application {
    .back-Img {
      display: none;
    }
  }
}
</style>
