<template>
  <div class="views-solution-Total ">
    <div class="solution-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">整体解决方案</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">INTEGRATED SOLUTION</h3>
    </div>
    <!-- hidden-sm-and-down -->
    <div class="Total-content ">
      <div class="Total-left">
        <div class="left-item "></div>
        <div class="right-item ">
          <div class="right-in">
            <div
                v-for="(item, index) in results"
                :key="index"
                class="rightContent"
                :class="{ active: index === zhaopinActive }"
                @click="onZhaopinClick(index)"
            >
              <div class="itemImg ">
                <img
                    v-lazy="zhaopinActive === index ? item.activeImg : item.img"
                    alt=""
                />
              </div>
              <div class="itemContent ">
                <h1>{{ item.title }}</h1>
                <ul v-for="(item, index) in item.content" :key="index">
                  <li>{{ item.name }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Total-right ">
        <div class="leftItem ">
          <img v-lazy="resultsList[zhaopinActive].img"/>
        </div>
        <div class="rightItem ">
          <!-- 一层作为过渡效果 一层作为动画效果 叠加效果宝石 -->
          <div class="smallImg" ref="smallImg" style="left: 20%;top: -7%;">
            <div class="myMoveUPDown"></div>
          </div>
          <img v-lazy="resultsList[zhaopinActive].imgItem"/>
        </div>
      </div>
    </div>
    <div class="back-Img"></div>
    <el-row class="Total-content-mobile hidden-md-and-up">
      <el-col
          v-for="(item, index) in results"
          :key="index"
          class="rightContent"
          :class="{ active: index === zhaopinActive }"
          @click="onZhaopinClick(index)"
          :xs="12"
          :sm="12"
          :md="12"
      >
        <div class="leftItem">
          <div class="itemImg">
            <img
                v-lazy="zhaopinActive === index ? item.activeImg : item.img"
                alt=""
            />
          </div>
          <div class="itemContent">
            <h1>{{ item.title }}</h1>
            <ul v-for="(item, index) in item.content" :key="index">
              <li>{{ item.name }}</li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import WOW from "wowjs";

  let Time = null; //定义一个全局定时器
  let time=null
  export default {
    name: "Total",

    props: {},
    data() {
      return {
        zhaopinActive: 0,
        num: 0,
           results: [
        {
          img: require("@/assets/images/solution/total-6.png"),
          activeImg: require("@/assets/images/solution/total-6.1.png"),
          title: "大数据可视化生产",
          content: [
            {
              name: "多源数据聚合",
            },
            {
              name: "数据挖掘和分析",
            },
            {
              name: "数字大屏可视化展示",
            },
          ],
        },
        {
          activeImg: require("@/assets/images/solution/total-4.1.png"),
          img: require("@/assets/images/solution/total-4.png"),
          title: "坐席运营管理系统",
          content: [
            {
              name: "统一坐席登入入口",
            },
            {
              name: "坐席考试培训",
            },
            {
              name: "任务管理中心",
            },
          ],
        },
        {
          activeImg: require("@/assets/images/solution/total-3.1.png"),
          img: require("@/assets/images/solution/total-3.png"),
          title: "智能业务运营系统",
          content: [
            {
              name: "项目集中管理",
            },
            {
              name: "智能任务派单",
            },
            {
              name: "任务执行监控",
            },
          ],
        },
        {
          activeImg: require("@/assets/images/solution/total-2.1.png"),
          img: require("@/assets/images/solution/total-2.png"),
          title: "智能外呼系统",
          content: [
            {
              name: "人机协同外呼",
            },
            {
              name: "全量语音分析",
            },
            {
              name: "挂机短信服务",
            },
          ],
        },
        {
          activeImg: require("@/assets/images/solution/total-5.1.png"),
          img: require("@/assets/images/solution/total-6.png"),
          title: "“AI助手”系统",
          content: [
            {
              name: "机器学习和深度学习",
            },
            {
              name: "优秀流程模型训练",
            },
            {
              name: "TTS语音模型管理",
            },
          ],
        },
        {
          activeImg: require("@/assets/images/solution/total-1.1.png"),
          img: require("@/assets/images/solution/total-1.png"),
          title: "智能权益运营系统",
          content: [
            {
              name: "品牌优惠券+积分兑换",
            },
            {
              name: "用户画像+智能权益匹配",
            },
            {
              name: "电子券+线下商圈",
            },
          ],
        },
      ],
      resultsList: [
        {
          img: require("@/assets/images/solution/total-7.png"),
          imgItem: require("@/assets/images/solution/total-8.png"),
        },
        {
          img: require("@/assets/images/solution/total-7.png"),
          imgItem: require("@/assets/images/solution/total-8.png"),
        },
        {
          img: require("@/assets/images/solution/total-7.png"),
          imgItem: require("@/assets/images/solution/total-8.png"),
        },
        {
          img: require("@/assets/images/solution/total-7.png"),
          imgItem: require("@/assets/images/solution/total-8.png"),
        },
        {
          img: require("@/assets/images/solution/total-7.png"),
          imgItem: require("@/assets/images/solution/total-8.png"),
        },
        {
          img: require("@/assets/images/solution/total-7.png"),
          imgItem: require("@/assets/images/solution/total-8.png"),
        },
      ],
      };
    },
    mounted() {
      //调用定时器
      this.setime();
      this.$nextTick(() => {
        let wow = new WOW.WOW({
          boxClass: "wow",
          animateClass: "animated",
          offset: 0,
          mobile: true,
          live: false,
        });
        wow.init();
      });
    },

    create() {
    },
    beforeDestroy() {
      clearInterval(Time);
      clearTimeout(time);
    },
    methods: {
      //自动行走
      setime() {
        Time = setInterval(() => {
          //每走动自增
          this.zhaopinActive++;
          //走动的最终距离大于等于数组长度
          if (this.zhaopinActive >= this.results.length) {
            //从零开始
            this.zhaopinActive = 0;
          }
          //将走动的位置传给左边的手动触发事件
          this.onZhaopinClick(this.zhaopinActive)
        }, 1500);
      },
//手动触发
      onZhaopinClick(index) {
        //获取smallImg dom的样式
        let dom = this.$refs.smallImg.style
        //如果有定时器 清除定时器
        if (Time) clearInterval(Time);
        switch (index) {
          case 0:
            dom.left = '20%';
            dom.top = '-7%';
            break;
          case 1:
            dom.left = '27%';
            dom.top = '5%';
            break;
          case 2:
            dom.left = '37%';
            dom.top = '14%';
            break;
          case 3:
            dom.left = '47%';
            dom.top = '23%';
            break;
          case 4:
            dom.left = '55%';
            dom.top = '34%';
            break;
          case 5:
            dom.left = '64%';
            dom.top = '44%';
            break;
        }
        //每次宝石行走的位置保存下来给一个变量
        this.zhaopinActive = index;
          //如果有手动定时器 清除定时器 调用自动定时器
        if (time) clearTimeout(time);//

        time = setTimeout(() => {//两秒后重新跑定时器
          this.setime();
        }, 2000);
      },
    },
  };
</script>
<style lang="less" scoped>
  .views-solution-Total {
    // width: 80%;
    // margin: 0 auto;

    .solution-title {
      margin-bottom: 1.0375rem;

      .line-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 63%;
        margin: 0 auto;

        .line-call {
          font-size: 0.5rem;
          font-family: SimHei;
          color: #333333;
          letter-spacing: 7px;
          white-space: nowrap;
        }

        .line-item {
          background: url("../../assets/images/product/line-title-right.png") no-repeat 100% 100%;
          height: 2px;
          width: 4.6375rem;
          background-size: contain;
        }

        .line-item-left {
          background: url("../../assets/images/product/line-title-left.png") no-repeat 100% 100%;
          height: 2px;
          width: 4.6375rem;
          background-size: contain;
        }
      }

      .line-title {
        margin-top: 20px;
        font-size: 0.25rem;
        font-family: SimHei;
        color: #666666;
        letter-spacing: 3px;
      }
    }

    .Total-content {
      width: 80%;
      margin: 0 auto;
      display: flex;

      .Total-left {
        display: flex;
        justify-content: space-between;
        position: relative;

        .left-item {
          background: url("../../assets/images/solution/total-10.png") no-repeat;
          background-size: cover;
          width: 2.925rem;
          height: 2.9375rem;
          transform: translateY(2.4625rem);
        }

        .right-item::before,
        .right-item::after {
          content: "";
          position: absolute;
          width: 10px;
          top: 0;
          height: 100%;
        }

        .right-item::before {
          right: -10px;
          background-image: radial-gradient(farthest-side at -50% 50%,
          hsla(0, 0%, 0%, 0.2),
          hsla(0, 0%, 0%, 0));
        }

        .right-item::after {
          left: -10px;
          transform: rotate(180deg);
          background-image: radial-gradient(farthest-side at -50% 50%,
          hsla(0, 0%, 0%, 0.2),
          hsla(0, 0%, 0%, 0));
        }

        .right-item {
          position: relative;
          transform: translateX(-1.25rem);
          width: 5.35rem;
          height: 7.175rem;
          box-sizing: border-box;
          padding: 2px;
          padding-bottom: inherit;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          background-image: -webkit-linear-gradient(90deg,
          #fff,
          rgba(24, 191, 246, 1));
          // background-image: -webkit-linear-gradient(#66b1ff, #fff) 30 30;

          // border: 3px solid rgba(58, 155, 245, 0.48);

          // border-radius: 10px;
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          // border: 3px solid rgba(58, 155, 245, 0.48);

          border-bottom: transparent;
          box-shadow: 0 0 3px 15px -5px rgba(0, 0, 0, 0.5);
          // border-image: -webkit-linear-gradient(#66b1ff, #f5f7fa) 30 30;
          // border-image: -moz-linear-gradient(#66b1ff, #f5f7fa) 30 30;
          // border-image: linear-gradient(#66b1ff, #fff) 30 30;

          // & ::after {
          //   content: "";
          // }

          .right-in {
            padding-top: 24px;
            width: 100%;
            height: 100%;

            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
            background: #ffffff;
            z-index: 1;
          }

          .rightContent {
            display: flex;

            padding-left: 25px;
            height: 0.75rem;
            align-items: center;

            .itemImg {
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .itemContent {
              padding-left: 0.3125rem;

              h1 {
                font-size: 0.3rem;
                font-family: SimHei;
                color: #666666;
                // transform: translateY(0.1875rem);
              }

              ul > li {
                display: none;
              }
            }

            &.active {
              height: 2.2125rem;

              width: 102%;
              transform: translateX(-3px);

              background: url("../../assets/images/solution/u30.png") no-repeat;
              background-size: 100% 100%;

              align-items: center;
              overflow: hidden;

              .itemImg {
                transform: translateY(-32px);

                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }

              h1 {
                font-size: 0.325rem;
                font-family: SimHei;
                color: #333333;
                padding-bottom: 0.275rem;
              }

              ul {
                margin-bottom: 5px;

                li {
                  display: block;
                  list-style-type: disc;
                  font-size: 0.175rem;
                  font-family: HYZhengYuan;
                  font-weight: normal;

                  text-align: left;
                  color: #666666;
                }

                li::before {
                  content: "";
                  display: inline-block;
                  width: 5px;
                  height: 5px;
                  background: #2399ff;
                  text-align: center;
                  border-radius: 50%;
                  box-sizing: border-box;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }

      .Total-right {
        display: flex;
        justify-content: space-between;
        position: relative;

        .leftItem {
          width: 1.725rem;
          height: 4.1125rem;
          // transform: translateY(1.89rem);
          z-index: 999;
          position: absolute;
          top: 24%;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .rightItem {
          width: 10.6rem;
          // height: 6.45rem;
          // transform: translateX(-0.5rem);
          position: absolute;
          left: 0.975rem;

          img {
            height: 100%;
            width: 100%;
          }
//专门为宝石图片设置两种类名两种效果
//一种过渡效果
          .smallImg {
            width: 19%;
            height: 32%;
            position: absolute;
            transition: all .5s linear;
            // animation: smallImg 4s steps(3, start) infinite alternate;
          }
//一种动画效果
          .myMoveUPDown {
            background: url("../../assets/images/solution/total-9.png") no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            animation: mymove 1s ease infinite alternate;
          }
        }
      }
    }

    .Total-content-mobile {
      display: none;

      .rightContent {
        margin: 25px 0;

        &.active {
          .leftItem {
            background: linear-gradient(0deg, #52b9f9, #bbdefb);
            color: #fff;

            .itemContent {
              text-align: left;
            }

            li::before {
              content: "";
              display: inline-block;
              width: 5px;
              height: 5px;
              background: #fff;
              text-align: center;
              border-radius: 50%;
              box-sizing: border-box;
              margin-right: 5px;
            }
          }
        }

        .leftItem {
          border: 3px solid #bbdefb;
          border-top: transparent;
          box-shadow: 0px 2px 2px 1px #bbdefb;
          box-sizing: border-box;
          background: #fff;
          border-radius: 27px;
          display: inline-block;
          position: relative;

          text-align: center;
          width: 87%;

          background: #fff;
          box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);

          .itemImg {
            height: 100%;
            width: 100%;
            transform: translateY(-20px);

            img {
              height: 1.25rem;
            }
          }

          .itemContent {
            padding: 0.3125rem;

            h1 {
              line-height: 160%;
              font-size: 0.225rem;
            }

            ul li {
              font-size: 0.175rem;
            }
          }
        }
      }
    }
  }

  @keyframes mymove {
    0% {
      transform: translateY(0);
    }
    0% {
      transform: translateY(-10px);
    }
  }

  //行走的砖石
  // @keyframes smallImg {
  //   0% {
  //     transform: translate(0.375rem, 0.375rem);
  //   }

  //   16% {
  //     transform: translate(1.825rem, 1.125rem);
  //   }
  //   33% {
  //     transform: translate(2.25rem, 1.475rem);
  //   }
  //   49% {
  //     transform: translate(2.775rem, 1.85rem);
  //   }
  //   65% {
  //     transform: translate(3.725rem, 2.35rem);
  //   }

  //   82% {
  //     transform: translate(4.675rem, 2.725rem);
  //   }

  //   100% {
  //     transform: translate(5.625rem, 3.125rem);
  //   }
  // }

  // @media screen and (min-width: 1920px) and(max-width: 2560px) {
  //   .views-solution-Total {
  //     .line-item-left {
  //     width: 0.75rem ;
  //   }
  //   }

  // }
  @media screen and (max-width: 1200px) {
    .views-solution-Total {
      .Total-content .Total-left .right-item {
        // width: 6.35rem;
        // height: 10.175rem;
        .rightContent {
          padding-left: 15px;

          &.active {
            padding-top: 0.125rem;

            h1 {
              font-size: 15px;
            }

            .itemContent {
              padding-top: 0;
            }
          }

          .itemContent {
            padding-top: 15px;

            h1 {
              font-size: 15px;
            }
          }
        }
      }

      .Total-content .Total-right {
        align-items: center;
      }

      .back-Img {
        transform: scale(0.8);
      }
    }
  }

  @media screen and (max-width: 992px) {
    .views-solution-Total {
      .Total-content {
        .Total-left {
          .left-item {
            display: none;
          }

          .right-item {
            width: 6.35rem;
            height: 8.75rem;
            // transform: translateX(0);
            .rightContent {
              height: 1.0625rem;

              &.active .itemImg {
                transform: inherit;
              }

              .itemContent {
                padding-left: 0;
              }
            }
          }
        }

        .Total-right {
          align-items: center;

          .rightItem {
            left: 37px;
          }
        }
      }

      .solution-title p {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .views-solution-Total {
      .Total-content {
        display: none;
      }

      .Total-content-mobile {
        display: block;
      }
    }

    // .views-solution-Core {
    //   .core-header {
    //     display: none;
    //   }
    //   .core-mobile-header {
    //     display: block;
    //   }
    //   .Total-content {
    //     transform: scale(0.6);
    //   }
    // }
  }

  @media screen and (max-width: 680px) {
    .views-solution-Total {
      .line-item {
        width: 0.75rem;
      }

      .line-item-left {
        width: 0.75rem;
      }
    }
  }

  // @media screen and (max-width: 480px) {
  //   .back-Img {
  //     display: none;
  //   }
  // }

  // @keyframes smallImg {
  //   0% {
  //     transform: translate(30px, 30px);
  //   }

  //   16% {
  //     transform: translate(146px, 90px);
  //   }
  //   33% {
  //     transform: translate(180px, 118px);
  //   }
  //   49% {
  //     transform: translate(222px, 148px);
  //   }
  //   65% {
  //     transform: translate(298px, 188px);
  //   }

  //   82% {
  //     transform: translate(374px, 218px);
  //   }

  //   100% {
  //     transform: translate(450px, 250px);
  //   }
  // }
</style>
