<template>
  <div class="views-solution-Banner ">
    <el-row class="Banner-header  hidden-sm-and-down">
      <div class="Banner-left-small">
        <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <div class="BannerTitle">
              <!-- 联通运营商 -->
              <img v-lazy="BannerTitle" />
            </div>
            <!-- <div class="word">TELECOM OPERATOR</div> -->

            <div class="Banner-content">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
      <!-- 大图 -->
      <div class="Banner">
        <div class="small-img"></div>
        <img :src="build" class="Banner-image" />
      </div>
      <div class="banner-foot">
        <div
          class="banner-item"
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ active: index === zhaopinActive }"
          @click="onZhaopinClick(index)"
        >
          <img v-lazy="item.img" alt="" />
          <span>{{ item.name }}</span>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </el-row>
    <!-- 移动端 -->
    <el-row class="Banner-header hidden-md-and-up">
      <el-col class="Banner-left-small">
        <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <div>
              <img v-lazy="BannerTitle" class="BannerTitle-mobile" />
            </div>
          </li>
        </ul>
      </el-col>
      <div class="Banner">
        <div class="small-img"></div>
        <img v-lazy="build" class="Banner-image" />
      </div>
      <el-col class="banner-foot">
        <div
          class="banner-item"
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ active: index === zhaopinActive }"
          @click="onZhaopinClick(index)"
        >
          <img v-lazy="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import WOW from "wowjs";
let Time = null; //定义一个全局定时器
let time = null;
export default {
  name: "Banner",

  props: {},
  data() {
    return {
      zhaopinActive: 0,
      num: 0,
      BannerTitle: require("@/assets/images/solution/banner-title.png"),
      build: require("@/assets/images/solution/banner.png"),
      tabList: [
        {
          name: "异网获客竞争激烈",
          img: require("@/assets/images/solution/tab-3.1.png"),
          activeImg: require("@/assets/images/solution/tab-3.png"),
          content:
            "携号转网给运营商带来很大的不确定性竞争风险，5G产品同质化导致异网拉新困难。",
        },
        {
          name: "用户关系维系困难",
          img: require("@/assets/images/solution/tab-1(1).png"),
          activeImg: require("@/assets/images/solution/tab-1.png"),
          content:
            "纯人工营销服务标准难统一，纯机器人营销不够智能，代理厂家众多导致数据泄露严重，多重难题混合造成用户服务体验差。",
        },
        {
          name: "粗放营销成本高昂",
          img: require("@/assets/images/solution/tab-2.png"),
          activeImg: require("@/assets/images/solution/tab-2.1.png"),
          content:
            "人力客服产能低下且流动性大，粗放式全量客户无差别触点营销成本投入大却转化率低。",
        },
      ],
      dataList: [
        {
          title: "UNICOM OPERATOR",
          content:
            "随着5G市场争夺的白热化和通信行业监管的日趋规范，市场竞争向高层次品质服务逐渐偏移。统一调度集约化生产提高运营能力,借助大数据分析精准营销改善营销体验，将成为运营商争夺市场的关键。",
        },
      ],
    };
  },
  mounted() {
    // this.setime();
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  create() {},
  beforeDestroy() {
    //页面销毁之前清楚定时器  页面销毁之前清除定时器
    clearInterval(Time);
  },
  methods: {
    setime() {
      //出现这么快的原因 是因为上一个定时器没有清理，导致两个定时器都触发了，所以在页面销毁的时候我们要清楚定时器

      Time = setInterval(() => {
        //setInterval循环定时器
        //定时器本身就是一个异步循环 得用箭头函数 否则定时器中的this指向windows而不是vue实例对象
        // console.log(this, "111");
        this.zhaopinActive++;
        if (this.zhaopinActive >= this.tabList.length) {
          this.zhaopinActive = 0;
        }
      }, 1500);
    },

    onZhaopinClick(index) {
      console.log(Time);

      if (Time) clearInterval(Time); //清楚全局定时器
      this.zhaopinActive = index;
      if (time) clearTimeout(time); //如果连续点击则会一直清楚定时器，不会在三秒后启动定时器 如果连续点击则会一直清除定时器 不会再三秒后自动定时器
      // console.log(index);
      time = setTimeout(() => {
        //setTimeout单次定时器，在三秒后自动启用定时器
      }, 2000);
    },
  },
};
</script>
<style lang="less" scoped>
.views-solution-Banner {
  // margin-top: 90px;
  .Banner-header {
    position: relative;
    .Banner {
      position: relative;
      .Banner-image {
        height: 459px;
        width: 100%;
      }

      .small-img {
        position: absolute;
        background: url("../../assets/images/solution/back-3.png") no-repeat;
        background-size: cover;
        left: 0;
        height: 404px;
        width: 3.75rem;
        bottom: 0;
        z-index: 1;
      }
    }

    .foote-item {
      position: absolute;
      left: 20%;
      bottom: -10px;
      background: white;
      width: 60%;
    }
    ul {
      display: flex;
      flex: 1;
      justify-content: space-around;
      align-items: center;
      height: 50px;
      li {
        position: relative;
        text-align: center;
      }
      li::after {
        position: absolute;
        content: "";
        right: -130px;
        height: 20px;
        margin: 0 auto;
        width: 0.01rem /* 80/80 */;
        background: #99a6cc;
      }
    }
    li:last-child::after {
      width: 0;
    }
    .banner-foot {
      display: flex;
      justify-content: center;
      transform: translateY(-45px);
      .banner-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 5.125rem;
        margin: 0 0.15rem;

        height: 2.475rem;
        box-sizing: border-box;

        // margin: auto;

        transform: translateY(-0.625rem);

        background: #ffffff;
        box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
        border-radius: 10px;
        transition: all 0.5s ease;

        img {
          // width: 50px;
          // height: 36px;
          max-width: 100%;
          transition: all 0.5s ease;
          // color: #3387f1;
          // background-color: #3387f1;
        }
        span {
          margin-top: 23px;
          font-size: 0.3rem;
          font-family: SimHei;
          color: #666666;
          transition: all 0.5s ease;
        }
        p {
          display: none;
        }
        &.active {
          background: linear-gradient(0deg, #dd765e, #fea76e);
          box-shadow: 0px 4px 29px 3px rgb(226 79 51 / 35%);
          transition: all 0.5s ease;
          padding: 0 0.7875rem;
          span {
            color: #ffffff;
          }
          img {
            display: none;
          }
          p {
            display: block;
            font-size: 0.2rem;
            font-family: SimHei;
            color: #ffffff;
            margin-top: 10px;
            letter-spacing: 1.5px;
            line-height: 1.5;
          }
        }
      }
    }
    .Banner-left-small {
      //   background: #5ebdf1;
      position: absolute;
      z-index: 1;
      left: 17%;
      top: 24%;
      width: 30%;

      .BannerTitle {
        text-align: left;
      }

      .Banner-title {
        font-size: 0.2rem;
        font-family: SimHei;
        color: #ffffff;
        text-align: left;
        margin: 0.125rem 0;
      }

      .Banner-content {
        font-family: SimHei;
        text-align: left;
        margin-top: 0.675rem;
        font-size: 0.2rem;

        color: rgba(255, 255, 255, 0.8);
        line-height: 25px;
        letter-spacing: 1px;
        opacity: 0.8;
      }
    }
  }
  .BannerTitle-mobile {
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .views-solution-Banner {
    .Banner-header {
      .Banner {
        .small-img {
          height: 404px;
          width: 200px;
        }
      }
      .banner-foot {
        transform: translateY(-30px);
        .banner-item{
         height: 3.475rem;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .views-solution-Banner {
    .Banner-header {
      .Banner {
        height: 100%;
        .Banner-image {
          height: 426px;
        }
      }
      .banner-foot {
        transform: translateY(-30px);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .views-solution-Banner {
    .Banner-header {
      .Banner {
        height: 100%;
        .Banner-image {
          height: 350px;
        }
        .small-img {
          height: 250px;
          width: 200px;
          bottom: 55px;
        }
      }
      .banner-foot {
        transform: translateY(-30px);
      }
    }
  }
}
.el-divider--horizontal {
  opacity: 0.47 !important;
}
.el-divider {
  background: #5ebdf1;
}
</style>
